import { configureStore } from "@reduxjs/toolkit";
import paymentSlices from "./slices/paymentSlices";
import sessionSlice from "./slices/sessionSlice";


const store = configureStore({
  reducer: {
    paymentSlices,
    sessionSlice,
  },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
