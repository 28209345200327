import { Route, Routes } from "react-router";
import "../src/scss/tipperUiSection/tipperCommon.scss";
import "./App.css";
import TippingPayment from "./pages/tippingUiSection";
import GuestyIntegration from "./pages/tippingUiSection/guestyIntegration";
import OrderDelievered from "./pages/tippingUiSection/orderDelievered";
import PaymentDone from "./pages/tippingUiSection/paymentDone";
import Error from "./pages/tippingUiSection/redirectPages/Error";
import NotFound from "./pages/tippingUiSection/redirectPages/NotFound";
import Success from "./pages/tippingUiSection/redirectPages/Success";

function App() {
  // useEffect(() => {
  //   clearCacheOnVersionChange(); // Run cache manager on app load
  // }, [])

  return (
    <>
      <Routes>
        <Route
          path="/guesty?/:token1?/:token2?/c?/org?"
          element={<TippingPayment />}
        />
        <Route path="/error" element={<Error />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/success" element={<Success />} />

        <Route path="/payment-confirm" element={<OrderDelievered />} />
        <Route path="/payment-completed" element={<PaymentDone />} />

        <Route path="/guesty-partner" element={<GuestyIntegration />} />
        {/* <Route path="image-loader" element={<StripeSkeleton />} /> */}
      </Routes>
    </>
  );
}

export default App;
