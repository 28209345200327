import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import ErrorBoundary from "./bugSnag/BugSnag";
import "./index.css";
import store from "./store/store";

// TypeScript version for root element creation and service worker registration
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <ToastContainer />
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);

// // Register the service worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/serviceWorker.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope: ', registration.scope);
//       })
//       .catch((error) => {
//         console.error('Service Worker registration failed: ', error);
//       });
//   });
// }

// // Listen for service worker controller change for page refresh
// navigator.serviceWorker.addEventListener('controllerchange', () => {
//   if ((window as any).refreshing) return;
//   (window as any).refreshing = true;
//   window.location.reload(); // Reload page to get fresh content
// });
