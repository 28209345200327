import { toast } from "react-toastify";
import { initialStateTypes } from "../types/paymentInitialState";
import * as yup from "yup";
import Bugsnag from "@bugsnag/js";
import { v4 as uuidv4 } from 'uuid';

export const parseJwt = (token: string) => {
  try {
    const [, payloadBase64] = token.split(".");
    const decodedPayload = atob(payloadBase64);
    const result = decodedPayload.split(/[&?]/);
    let obj: any = {};
    result.forEach((item) => {
      if (item !== "") {
        const [key, value] = item.split("=");
        obj[key] = value;
      }
    });
    return obj;
  } catch (error: any) {
    notifyBugsnagError(error, {
      InValid: "parseJwt",
    });
    // console.error("Error parsing JWT:", error);
    return null;
  }
};

export const checkType = (type: string | undefined) => {
  let result;
  if (type === "qr_property") {
    result = "pid";
  }
  if (type === "qr_room") {
    result = "rid";
  }
  if (type === "qr_employee") {
    result = "eid";
  }
  if (type === "qr_service") {
    result = "sid";
  }
  return result ?? "";
};

/**
 * Calculate the organization comission
 * @param tipAmount
 */
const checkOrganizationComission = (tipAmount: number, orgData: any) => {
  // let tippedAmount = tipAmount;
  // let adminCharge = 0.49;
  // if (orgData && orgData?.uuid) {
  //   if (orgData?.top_tipper_fees) {
  //     adminCharge = orgData?.top_tipper_fees;
  //   } else {
  //     if (tippedAmount > 9.9 && tippedAmount < 50) {
  //       adminCharge = 0.99;
  //     } else if (tippedAmount > 49.99) {
  //       adminCharge = 1.5;
  //     }
  //   }
  // }
  // return adminCharge.toFixed(2);
  let adminCharge = orgData?.top_tipper_fees;
  return adminCharge?.toFixed(2);
};

export const calculateFee = (value: string, orgData: any): [string, string] => {
  if (value !== "" && +value > 0) {
    let tipAmount = parseFloat(value);
    let topTipperComission = checkOrganizationComission(tipAmount, orgData);
    let totalTipAmount = (
      (tipAmount + 0.3 + parseFloat(topTipperComission)) /
      0.971
    ).toFixed(2);
    let stripeCut: string | number =
      parseFloat(totalTipAmount) - (tipAmount + parseFloat(topTipperComission));
    stripeCut = stripeCut.toFixed(2);
    let totalApplicationFee = Number(topTipperComission) + Number(stripeCut);
    return [totalApplicationFee.toFixed(2), stripeCut];
  } else {
    return ["", ""];
  }
};

export function isValidEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export function isValidUrl(url: string) {
  if (url && url?.length <= 50) {
    return true;
  } else {
    return false;
  }
}

export const guestyValidation = yup.object({
  first_name: yup.string().required("Manager Name is required."),
  name: yup.string().required("Organization Name is required."),
  email: yup
    .string()
    .email("Invalid email address.")
    .required("Email is required."),
  token: yup.string().required("Integration Token is required."),
  url_code: yup
    .string()
    .max(50, "URL Format must not exceed 50 characters.")
    .required("URL Abbreviation is required."),
  password: yup
    .string()
    .trim()
    .required(`Password is required.`)
    .max(20, "Maximum 20 characters.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
      "Minimum 8 Characters(1 upper & lower case, number and special character)."
    ),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf(
      [yup.ref("password"), ""],
      `Password and Confirm Password doesn't match. `
    )
    .required("Confirm Password is required."),
});

export const showToast = (message: string, type: "success" | "error") => {
  if (type === "success") {
    toast.dismiss();
    toast.success(message, { autoClose: 5000 });
  } else if (type === "error") {
    toast.dismiss();
    toast.error(message, { autoClose: false });
  }
};

export const removeInitialSpace = (value: string) => value.replace(/^\s+/g, "");

export const notifyBugsnagError = (
  error: Error | string,
  metadata?: Record<string, any>
) => {
  Bugsnag.notify(error, (event) => {
    if (metadata) {
      event.addMetadata("custom", metadata);
    }
  });
};

export const handleSetupIntentPayload = (
  propertyId: number | undefined,
  serviceId?: number | undefined,
  employeeId?: number | undefined
) => {
  let payload: { type: number | null; uuid: number | null } = {
    type: null,
    uuid: null,
  };
  if (propertyId && serviceId && employeeId) {
    payload.type = 3;
    payload.uuid = employeeId;
  } else if (propertyId && serviceId) {
    payload.type = 5;
    payload.uuid = serviceId;
  } else if (propertyId && employeeId) {
    payload.type = 3;
    payload.uuid = employeeId;
  }
  else if (propertyId) {
    payload.type = 2;
    payload.uuid = propertyId;
  }

  return payload;
};

export const getUserId = () => {
  let token: any = localStorage.getItem('token');
  try {
    // Parse token, which could either be JSON or plain text
    const parsedToken = JSON.parse(token);

    // Try decoding as base64
    try {
      const decodedToken: any = atob(parsedToken); // Decode base64
      const utf8Decoder = new TextDecoder();
      const utf8Token = utf8Decoder.decode(
        new Uint8Array([...decodedToken].map((char) => char.charCodeAt(0)))
      );

      // Split token and return the first part
      const jwtData = utf8Token.split("/");
      return jwtData?.length > 1 ? jwtData[0] : parsedToken;
    } catch {
      // If base64 decoding fails, treat it as a plain string
      return parsedToken;
    }
  } catch (error) {
    console.error("Invalid token format:", error);
    return null; // Return null for invalid tokens
  }
}

export const createNewSession = (uuid: any) => {

  const newSessionId = uuidv4() // Unique session ID
  // localStorage.setItem(`${uuid + 'userSession'}`, JSON.stringify(newSessionId));
  return newSessionId;
};

// export const getSessionId = () => {
//   const userId = getUserId()
//   const sessionKey = `${userId + 'userSession'}`;
//   const sessionId = localStorage.getItem(sessionKey);
//   return sessionId ? JSON.parse(sessionId) : null;
// };

export const saveTokens = (token: any) => {

  if (token) {
    localStorage.setItem('token', JSON.stringify(token))
  }
}

export const removeAllTokenAndSessionIds = () => {
  const userId = getUserId()
  localStorage.removeItem(`token`)
  localStorage.removeItem(`${userId}userSession`)
}
/** Function to clear cache when build updated */
// export const clearCacheOnVersionChange = () => {
//   const currentVersion = process.env.REACT_APP_VERSION; // Get version from package.json
//   const storedVersion = localStorage.getItem('app_version');
//   if (storedVersion !== currentVersion) {
//     console.log('New version detected. Clearing cache...');
//     localStorage.clear(); // Clear any necessary caches (localStorage, sessionStorage, etc.)
//     sessionStorage.clear();
//     clearCookies()
//     localStorage.setItem('app_version', currentVersion!); // Store the new version
//   } else {
//     console.log('Same version. No cache cleared.');
//   }
// };

// const clearCookies = () => {
//   // Get all cookies stored in the browser as a single string
//   // Each cookie is separated by a semicolon (';'), so we split them into an array
//   const cookies = document.cookie.split(";");
//   // Loop through each cookie in the array
//   for (let i = 0; i < cookies.length; i++) {
//     // Get the individual cookie string, which is in the format "name=value"
//     const cookie = cookies[i];
//     // Find the position of the equal sign that separates the cookie name from its value
//     const eqPos = cookie.indexOf("=");
//     // Extract the cookie name:
//     // - If an equal sign exists, the name is everything before it (substring 0 to the position of '=')
//     // - If no equal sign, the cookie is malformed, so treat the entire string as the name
//     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//     // Clear the cookie by setting it with:
//     // 1. Same name.
//     // 2. An empty value (name + "=").
//     // 3. A past expiration date (Jan 1, 1970), making it invalid.
//     // 4. The path set to "/", ensuring the cookie is cleared for the entire site.
//     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
//   }
// };

