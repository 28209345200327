import { createSlice } from "@reduxjs/toolkit";
import { sessionInitialStateType } from "../../types/sessionInitialState";

const initialState: sessionInitialStateType = {
    sessionId: ''

};

export const sessionSlice = createSlice({
    name: "session-data",
    initialState,
    reducers: {
        setSessionId(state, { payload }) {
            state.sessionId = payload;
        },
    },

});

export default sessionSlice.reducer;
export const { setSessionId } = sessionSlice.actions;